import { useAuth } from "@/state/auth";
import { watchOnce } from "@vueuse/core";
import { Router } from "vue-router";

const title = document.title;

export const defineGaurds = (router: Router) => {
  router.beforeEach(async (to) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      const { isLoggedIn, isStillChecking } = useAuth();

      if (isStillChecking.value) {
        await new Promise<void>((resolve) => {
          watchOnce(isStillChecking, () => {
            resolve();
          });
        });
      }
      if (!isLoggedIn.value) {
        return {
          name: "login",
        };
      }
    }
  });

  /**
   * Route Guard.
   * prepends the view name to the document title
   */
  router.afterEach((to) => {
    document.title =
      to.name && typeof to.name === "string"
        ? title + " | " + to.name[0].toUpperCase() + to.name.substring(1)
        : title;
  });
};
